.siteHeader {
    background-color: #282c34;
    min-height: calc(30px + 2vmin);
    font-size: calc(10px + 2vmin);
    color: white;
  }

.navBar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.navElement {
    vertical-align: middle;
    min-height: calc(30px + 2vmin);
    cursor: pointer;
    padding: 0 1rem;
    color: inherit;
    text-decoration: none;
}

.navElement:active, .navElement:hover {
    background-color: #3d424d;
}